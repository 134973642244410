<template>
    <el-dialog :visible.sync="dialogVisible" class="history-order__form-group history-order__transaction-popup prolongation-popup" :before-close="handleClose">
        <div class="col-md-12 form-group">
            <div class="popup-header">
                <label>Увага!</label>
            </div>
            <hr class=""/>
            <label class="label-text">Нажаль ця пропозиція недоступна, оберіть будь-ласка нову.</label>

        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" class="prolongModalBtn" @click="prolongNew">
                Обрати нову пропозицію
            </el-button>
<!--            <el-button @click="dialogVisible = false">Відміна</el-button>-->
        </span>
    </el-dialog>
</template>

<script>
import ClickOutside from 'vue-click-outside'

    export default {
        name: 'ProlongationModal',
        directives: { ClickOutside },
        props: {
            isShow: {
                type: Boolean,
            },
        },
        data: function () {
            return {
                condition: {
                    isShow: false,
                },
                order: null,
                dialogVisible: false,
            }
        },
        methods: {
            prolongNew: function () {
                this.$emit('prolong-new', this.order);
                this.handleClose();
            },
            hide: function () {
                return this.$emit('hide-prolongation-modal');
            },
            handle: function () {
                return this.condition.isShow = ! this.condition.isShow
            },
            show: function(order) {
                this.dialogVisible = true;
                this.order = order;
            },
            handleClose: function() {
                this.dialogVisible = false;
                this.order = null;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .prolongModalBtn {
        color: #fff!important;
        font-weight: 500!important;
        font-size: 16px;
    }
    .info-block {
        padding-left: 30px;
        z-index: 2;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        width: 320px;
        position: absolute;
        background: #FFFFFF;
        top: 30px;
        left: -158px;

        .option {
            margin-top: 16px;
            font-family: Circe,serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: #999DA6;
        }
        .info {
            font-family: Circe, serif;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 29px;

            color: #24282C;
        }
        svg:hover {
            fill: #FA9B13;
        }
    }

</style>
