import { render, staticRenderFns } from "./prolongFilter.vue?vue&type=template&id=608570ec&scoped=true&"
import script from "./prolongFilter.vue?vue&type=script&lang=js&"
export * from "./prolongFilter.vue?vue&type=script&lang=js&"
import style0 from "./prolongFilter.vue?vue&type=style&index=0&id=608570ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608570ec",
  null
  
)

export default component.exports